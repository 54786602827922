<template>
  <div :class="formGroupClasses">
    <label v-if="label"
           :for="id">
      {{ label }}
    </label>
    <div class="input-group">
      <input v-model="text"
             @keydown.esc="close"
             :type="type"
             :id="id"
             :name="name"
             :value="value"
             :placeholder="placeholder"
             :disabled="disabled"
             class="form-control">
      <div v-if="text || selected || options.length"
           class="input-group-append">
        <div class="inner">
          <i @click="close" class="icon-delete"></i>
        </div>
      </div>
    </div>
    <div v-if="options.length"
         class="features">
      <div v-for="(city, index) in options"
           :key="index"
           @click="selectCity(city)"
           class="feature">
        {{ city.name + ', ' + city.num_department + ', ' + city.country_name }}
      </div>
    </div>
    <div v-if="error"
         class="help-block">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce'
import { autocompleteCity } from '@/api/premat'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    value: {
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      text: null,
      options: [],
      selected: null,
      editMode: false
    }
  },
  computed: {
    formGroupClasses () {
      return {
        'form-group': true,
        'has-error': !!this.error,
        'has-label': !!this.label
      }
    }
  },
  methods: {
    autocomplete (text) {
      if (!text) return
      this.options = autocompleteCity(text)
        .then(response => {
          this.options = response
        })
        .catch(error => console.log(error))
    },
    selectCity (city) {
      this.selected = city
      this.options = []
      this.text = city.name + ', ' + city.num_department + ', ' + city.country_name
      this.$emit('input', this.text)
    },
    close () {
      this.text = null
      this.selected = null
      this.options = []
      this.$emit('input', null)
    }
  },
  watch: {
    text (value) {
      if (this.selected && value === this.selected.name) return
      // below, a dirty hack to show only the text and no options in edit mode
      if (this.editMode === true) {
        this.editMode = false
      } else {
        this.debounceAutocomplete(value)
      }
    }
  },
  created () {
    this.debounceAutocomplete = debounce(this.autocomplete, 300)
    if (typeof this.value === 'string') {
      this.editMode = true
      this.text = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-group {
    position: relative;
  }

  .features {
    z-index: 999;
    position: absolute;
    top: 38px;
    left: 0;
    width: 100%;
    background: $white;
    border: 1px solid $red;
    border-top: 1px solid rgba($red, 0);
    overflow-y: auto;
    max-height: 200px;

    @media (max-width: $breakpoint-md) {
      top: 34px;
      font-size: 13px;
      width: auto;
      border-top: 1px solid $red;
    }
  }

  .has-label .features {
    top: 60px;
  }

  .feature {
    box-sizing: border-box;
    padding: 5px 10px;

    @media (max-width: $breakpoint-md) {
      padding: 3px 5px;
    }

    &:hover {
      background: rgba($red, .05);
      cursor: pointer;
    }
  }

  .input-group-append .inner i {
    &:hover {
      cursor: pointer;
    }
  }
</style>
