<template>
  <div :class="formGroupClasses">
    <label v-if="label"
           :for="id">
      {{ label }}
    </label>
    <textarea @input="updateValue($event.target.value)"
              :id="id"
              :name="name"
              :value="value"
              :placeholder="placeholder"
              :disabled="disabled"
              :rows="rows"
              class="form-control"></textarea>
    <div v-if="error"
         class="help-block">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    rows: {
      type: Number,
      required: false,
      default: 3
    },
    error: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    formGroupClasses () {
      return {
        'form-group': true,
        'has-error': !!this.error
      }
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>
