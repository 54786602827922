<template>
  <div v-if="authorization"
       class="authorizations-edit-page">
    <AuthorizationBreadcrumb :active-step="1"/>
    <div class="page-content">
      <div class="container">
        <div v-if="error"
             class="row">
          <div class="col-md-12">
            <FormError :error="error"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <FormCityAutocomplete v-model="cityStart"
                                  id="startCity"
                                  name="startCity"
                                  type="text"
                                  label="Ville de départ"
                                  placeholder="Saisir et sélectionner une ville"/>
          </div>
          <div class="col-md-6">
            <FormCityAutocomplete v-model="cityEnd"
                                  id="endCity"
                                  name="endCity"
                                  type="text"
                                  label="Ville d'arrivée"
                                  placeholder="Saisir et sélectionner une ville"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <FormInput v-model="authorization.num_authorization_on_pdf"
                       type="text"
                       id="num_authorization_on_pdf"
                       name="num_authorization_on_pdf"
                       label="Numéro de l'autorisation"
                       placeholder="Numéro"/>
          </div>
          <div class="col-md-4">
            <FormInput v-model="internalNum"
                       type="text"
                       id="num"
                       name="num"
                       label="Numéro interne"
                       placeholder="Numéro"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <FormSelect v-model="authorization.tonnage"
                        :selected-at-startup="authorization.tonnage"
                        id="tonnage"
                        name="tonnage"
                        label="Tonnage"
                        placeholder="Sélectionner"
                        :options="tonnages"
                        value-to-select="tonnage"
                        label-field="tonnage"/>
          </div>
          <div class="col-md-3">
            <FormInput v-model="weight"
                       type="number"
                       id="weight"
                       name="weight"
                       label="Poids (en kg)"
                       placeholder="kg"/>
          </div>
          <div class="col-md-6">
            <FormInput v-model="dimensions"
                       type="text"
                       id="dimensions"
                       name="dimensions"
                       label="Longueur x largeur x hauteur (en m)"
                       placeholder="00,00x00,00x00,00"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <FormDatePicker v-model="dateStart"
                            id="date_start"
                            name="date_start"
                            label="Date de début de validité"
                            placeholder="JJ/MM/AAAA"/>
          </div>
          <div class="col-md-4">
            <FormDatePicker v-model="dateEnd"
                            id="date_end"
                            name="date_end"
                            label="Date de fin de validité"
                            placeholder="JJ/MM/AAAA"/>
          </div>
          <div class="col-md-4">
            <FormFileInput v-model="pdf"
                           id="pdf"
                           name="pdf"
                           label="Document de l'autorisation"
                           :filename="pdfName"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <FormTextArea v-model="authorization.comment"
                          id="comment"
                          name="comment"
                          label="Commentaire"
                          placeholder="Texte de commentaire"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button @click="cancel"
                    class="btn btn-outline-secondary mr-2">
              Annuler
            </button>
            <button @click="submit"
                    :disabled="!formFilled"
                    class="btn btn-secondary">
              Mettre à jour
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthorizationBreadcrumb from '@/components/AuthorizationBreadcrumb'
import FormCityAutocomplete from '@/components/FormCityAutocomplete'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import FormDatePicker from '@/components/FormDatePicker'
import FormTextArea from '@/components/FormTextArea'
import FormFileInput from '@/components/FormFileInput'
import FormError from '@/components/FormError'
import { getAuthorization, listCategories, updateAuthorization } from '@/api/premat'
import { dateStringToTimestamp, formatDimensionsEdit, formatWeight, parseDate, parseDimensions, parseWeight } from '@/utils'

export default {
  name: 'AuthorizationsEditPage',
  components: {
    AuthorizationBreadcrumb,
    FormCityAutocomplete,
    FormInput,
    FormSelect,
    FormDatePicker,
    FormTextArea,
    FormFileInput,
    FormError
  },
  data () {
    return {
      authorization: null,
      tonnages: [],
      error: null,
      hasChangedCityStart: false,
      hasChangedCityEnd: false,
      hasChangedWeight: false,
      hasChangedInternalNum: false,
      parsedWeight: null,
      hasChangedDimensions: false,
      parsedDimensions: null,
      hasChangedDateStart: false,
      parsedDateStart: null,
      hasChangedDateEnd: false,
      parsedDateEnd: null,
      pdf: null
    }
  },
  computed: {
    pdfName () {
      if (!this.authorization || !this.authorization.pdf_path) return null
      return this.authorization.pdf_path.split('/').pop() || null
    },
    cityStart: {
      get () {
        return this.authorization.city_start
      },
      set (value) {
        this.hasChangedCityStart = true
        this.authorization.city_start = value
      }
    },
    cityEnd: {
      get () {
        return this.authorization.city_end
      },
      set (value) {
        this.hasChangedCityEnd = true
        this.authorization.city_end = value
      }
    },
    internalNum: {
      get () {
        return this.authorization.num
      },
      set (value) {
        this.hasChangedInternalNum = true
        this.authorization.num = value
      }
    },
    weight: {
      get () {
        return formatWeight(this.authorization, '').replace(/,/g, '.')
      },
      set (value) {
        this.hasChangedWeight = true
        const weight = parseWeight(value, false)
        this.parsedWeight = {
          tonne: weight.tonne,
          kilogram: weight.kilogram
        }
        return weight
      }
    },
    dimensions: {
      get () {
        return formatDimensionsEdit(this.authorization).replace(/\./g, ',')
      },
      set (value) {
        this.hasChangedDimensions = true
        const dimensions = parseDimensions(value, false)
        this.parsedDimensions = {
          length: parseFloat(dimensions.length).toString(),
          width: parseFloat(dimensions.width).toString(),
          height: parseFloat(dimensions.height).toString()
        }
        return value
      }
    },
    dateStart: {
      get () {
        return dateStringToTimestamp(this.authorization.date_start)
      },
      set (value) {
        if (!value) return
        this.hasChangedDateStart = true
        this.parsedDateStart = parseDate(value, 'date de début de validité').replace(/\//g, '-')
        return value
      }
    },
    dateEnd: {
      get () {
        return dateStringToTimestamp(this.authorization.date_end)
      },
      set (value) {
        if (!value) return
        this.hasChangedDateEnd = true
        this.parsedDateEnd = parseDate(value, 'date de fin de validité').replace(/\//g, '-')
        return value
      }
    },
    formFilled () {
      // todo double check it works
      let filled = !!this.authorization.num_authorization_on_pdf
        && !!this.authorization.date_start
        && !!this.authorization.date_end
        && !!this.authorization.tonnage
        && !!this.weight
        && !!this.authorization.num
      if (this.hasChangedCityStart) {
        filled = filled
          && !!this.authorization.city_start
      }
      if (this.hasChangedCityEnd) {
        filled = filled
          && !!this.authorization.city_end
      }
      if (this.hasChangedWeight) {
        filled = filled
          && this.parsedWeight !== null
          && (this.parsedWeight.tonne !== null && !isNaN(this.parsedWeight.tonne))
          && (this.parsedWeight.kilogram !== null && !isNaN(this.parsedWeight.kilogram))
      } else {
        filled = filled && !!this.weight
      }
      if (this.hasChangedDimensions) {
        filled = filled
          && this.parsedDimensions !== null
          && (this.parsedDimensions.length !== null && !isNaN(this.parsedDimensions.length))
          && (this.parsedDimensions.width !== null && !isNaN(this.parsedDimensions.width))
          && (this.parsedDimensions.height !== null && !isNaN(this.parsedDimensions.height))
      } else {
        filled = filled && !!this.dimensions
      }
      return filled
    }
  },
  methods: {
    getTonnages () {
      listCategories()
        .then(response => {
          this.tonnages = response.data.categories
        })
        .catch(() => {
          this.error = {
            message: 'Impossible de récupérer la liste des tonnages, veuillez contacter l\'administrateur du site'
          }
        })
    },
    getAuthorization () {
      getAuthorization(this.$route.params.id)
        .then(response => {
          this.authorization = response.data.authorization
        })
        .catch(() => this.$router.push({ name: '404' }))
    },
    cancel () {
      this.$router.push({
        name: 'authorizations.show',
        params: {
          id: this.$route.params.id
        }
      })
    },
    submit () {
      if (!this.formFilled) return
      this.error = null
      console.log('DEBUG: ', this.getFormData())
      updateAuthorization(this.authorization.id, this.getFormData()).then(response => {
        this.$router.push({
          name: 'authorizations.show',
          params: response.data.authorization.id
        })
      }).catch(error => {
        if (error.response) {
          this.error = error.response.data
        } else {
          this.error = {
            message: 'Une erreur est survenue, merci de réessayer...'
          }
        }
      })
    },
    getFormData () {
      const formData = new FormData()
      if (this.hasChangedCityStart) {
        formData.append('city_start', this.authorization.city_start)
      }
      if (this.hasChangedCityEnd) {
        formData.append('city_end', this.authorization.city_end)
      }
      if (this.hasChangedInternalNum) {
        formData.append('num', this.authorization.num)
      }
      formData.append('num_authorization_on_pdf', this.authorization.num_authorization_on_pdf)
      formData.append('tonnage', this.authorization.tonnage)
      if (this.hasChangedWeight) {
        formData.append('tonne', this.parsedWeight.tonne)
        formData.append('kilogram', this.parsedWeight.kilogram)
      }
      if (this.hasChangedDimensions) {
        formData.append('length', this.parsedDimensions.length)
        formData.append('width', this.parsedDimensions.width)
        formData.append('height', this.parsedDimensions.height)
      }
      if (this.hasChangedDateStart) {
        formData.append('date_start', this.parsedDateStart)
      }
      if (this.hasChangedDateEnd) {
        formData.append('date_end', this.parsedDateEnd)
      }
      if (this.pdf) {
        formData.append('pdf', this.pdf)
      }
      formData.append('comment', this.authorization.comment)
      return formData
    }
  },
  mounted () {
    this.getTonnages()
    this.getAuthorization()
  }
}
</script>

<style scoped>
  .page-content {
    padding-top: 2em;
  }
  .authorizations-edit-page {
    flex-direction: column;
  }
</style>
