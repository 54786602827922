<template>
  <div class="authorization-breadcrumb">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10">
          <div class="breadcrumb-line-container">
            <div class="breadcrumb-line"></div>
          </div>
          <div class="breadcrumb-steps d-flex justify-content-between mt-2">
            <div v-for="step in breadcrumbSteps"
                 :key="step.number"
                 :class="breadcrumbStepClasses(step)">
              <div class="step-number">{{ step.number }}</div>
              <div class="step-name">{{ step.text }}</div>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-right">
          <div class="breadcrumb-actions">
            <router-link :to="{ name: 'authorizations.index' }"
                         class="btn btn-sm btn-outline-secondary mr-2">
              Annuler
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeStep: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      breadcrumbSteps: [
        {
          number: 1,
          text: 'Informations'
        },
        {
          number: 2,
          text: 'Itinéraire'
        },
        {
          number: 3,
          text: 'Visualisation'
        },
        {
          number: 4,
          text: 'Validation'
        }
      ]
    }
  },
  methods: {
    breadcrumbStepClasses (step) {
      return {
        'breadcrumb-step': true,
        active: this.activeStep === step.number
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .authorization-breadcrumb {
    position: relative;
    padding: 0 30px 30px 30px;
    border-bottom: 1px solid rgba($gray-text-light, .2);

    @media (max-width: $breakpoint-md) {
      padding: 0 30px 25px 10px;
      font-size: 13px;
    }

    @media (max-width: $breakpoint-sm) {
      padding: 0 10px 15px 10px;
    }
  }

  .breadcrumb-line-container {
    position: absolute;
    top: 0;
    left: 40px;
    width: 90%;
    height: 100%;
    padding: 0 50px;
  }

  .breadcrumb-line {
    position: absolute;
    border-bottom: 1px solid $gray-light;
    height: 1px;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;

    @media (max-width: $breakpoint-sm) {
      padding-top: 4px;
    }

    @media (max-width: $breakpoint-sm) {
      display: none;
    }
  }

  .breadcrumb-step {
    background: $white;
    padding: 0 30px;
    z-index: 2;

    @media (max-width: $breakpoint-md) {
      padding: 0 15px;
    }

    &.active .step-number {
      background: $red;
    }
  }

  .step-number {
    color: $white;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 24px;
    background: $gray-text;
    text-align: center;
    display: inline-block;
    margin-right: 14px;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      width: 18px;
      height: 18px;
      line-height: 18px;
      border-radius: 18px;
      margin-right: 8px;
      vertical-align: 1px;
    }
  }

  .step-name {
    display: inline-block;
    color: $gray-text-light;
    line-height: 24px;

    @media (max-width: $breakpoint-md) {
      line-height: 18px;
    }
  }

  .breadcrumb-actions {
    @media (max-width: $breakpoint-sm) {
      padding: 0 15px;
    }

    .btn {
      @media (max-width: $breakpoint-sm) {
        display: inline-block;
        width: 100%;
        margin-top: 12px;
      }
    }
  }
</style>
