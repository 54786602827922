<template>
  <div :class="formGroupClasses">
    <label v-if="label"
           :for="id">
      {{ label }}
    </label>
    <div class="custom-file">
      <input @change="updateValue($event.target)"
             ref="fileInput"
             type="file"
             :id="id"
             :name="name"
             :disabled="disabled"
             class="custom-file-input">
      <label :for="id"
             class="custom-file-label"
             data-browse="">
        {{ filename }}
      </label>
      <div class="pdf-icon">
        <i class="icon-PDF"></i>
      </div>
    </div>
    <div v-if="error"
         class="help-block">
      {{ error }}
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import bsCustomFileInput from 'bs-custom-file-input'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    value: {
      // type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    filename: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    formGroupClasses () {
      return {
        'form-group': true,
        'has-error': !!this.error
      }
    }
  },
  methods: {
    updateValue (target) {
      if (!target.files[0].name.endsWith('.pdf')) {
        alert('Le document de l\'autorisation doit être un fichier pdf')
        this.$emit('input', null)
        this.$refs.fileInput.value = ''
        return
      }
      this.$emit('input', target.files[0])
    }
  },
  mounted () {
    $(document).ready(() => {
      bsCustomFileInput.init()
    })
  },
  beforeDestroy () {
    bsCustomFileInput.destroy()
  }
}
</script>

<style scoped lang="scss">
  label.custom-file-label {
    text-transform: none;
  }

  .custom-file {
    border-bottom: 1px solid $red;
    position: relative;
  }

  .pdf-icon {
    position: absolute;
    top: 5px;
    right: 2px;
    z-index: 999;
    font-size: 22px;
    pointer-events: none;

    @media (max-width: $breakpoint-md) {
      top: 10px;
      font-size: 19px;
    }
  }

  input[type=file]:hover {
    cursor: pointer;
  }
</style>
